<template>
  <van-popup
    v-model="isShow"
    round
    :close-on-click-overlay="false"
    @click-overlay="closePopup"
    position="bottom"
  >
    <van-cascader
      v-model="regionVal"
      title="请选择所在地区"
      :options="options"
      @close="closePopup"
      @finish="getDistrict"
    />
  </van-popup>
</template>

<script>
import regionThree from "@/common/region/regionThree";
export default {
  name: "cascader-popup",
  props: {
    isShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      options: regionThree,
      regionVal: "",
    };
  },
  mounted() {},
  methods: {
    getDistrict({ selectedOptions }) {
      // 获取区域信息
      console.log(selectedOptions);
      let location = "";
      let locationObj = {
        province_id: selectedOptions[0].value,
        city_id: selectedOptions[1].value,
        area_id: selectedOptions[2].value,
      };
      location = selectedOptions.map((option) => option.text).join(" ");
      this.regionVal = "";
      this.$emit("setLocationInfo", locationObj, location);
    },
    closePopup() {
      this.$emit("setLocationInfo");
    },
  },
};
</script>

<style lang="less" scoped>
</style>