<template>
  <van-popup
    v-model="isShow"
    :close-on-click-overlay="false"
    duration="0"
    :overlay="false"
    position="bottom"
    :style="{ height: '100%' }"
  >
    <div class="nav-bar">
      <van-icon
        name="arrow-left"
        class="icon-nav"
        size="20"
        color="#9B9B9B"
        @click="closePopup"
      />
      <span>收货地址</span>
    </div>
    <van-radio-group
      v-model="radio"
      class="radio--group"
      @change="addressChange"
    >
      <van-radio
        :name="item.id"
        checked-color="#ee0a24"
        class="van--radio"
        v-for="item in list"
        :key="item.id"
      >
        <div class="select-address-one">
          <div class="address-info">
            <div class="user-info">
              <span class="user-name">{{ item.consignee }}</span>
              <span class="phone">{{ item.phone }}</span>
              <span class="default" v-if="item.is_default === 1">默认</span>
            </div>
            <div class="address">
              {{ item.city_names }}  {{ item.address }}
            </div>
          </div>
          <van-icon
            name="edit"
            color="#AAAAAA"
            size="20"
            @click="editLocation(item.id)"
            @click.stop
          />
        </div>
      </van-radio>
    </van-radio-group>
    <div class="button">
      <van-button
        round
        block
        icon="plus"
        color="linear-gradient(to right, #FF3F4E, #FF3300)"
        type="danger"
        @click="editLocation"
      >
        新增地址
      </van-button>
    </div>
    <AddLocation
      :isShow="showLocation"
      :addressId="addressId"
      @updateLocation="updateLocation"
    ></AddLocation>
  </van-popup>
</template>

<script>
import AddLocation from "./add-location";
import { getAddressList } from "@/api/member";
export default {
  name: "select-address",
  components: {
    AddLocation,
  },
  props: {
    isShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      radio: 1,
      addressId: 0,
      list: [],
      showLocation: false,
    };
  },
  mounted() {
    this.getAddressList();
  },
  methods: {
    editLocation(id) {
      if (id > 0) {
        this.addressId = id;
      }
      this.showLocation = true;
    },
    addressChange(locationId) {
      this.$emit("getAddress", locationId);
    },
    updateLocation() {
      this.showLocation = false;
      this.getAddressList();
    },
    closePopup() {
      this.$emit("getAddress");
    },
    async getAddressList() {
      const result = await getAddressList();
      // console.log(result);
      this.list = result.data;
      for (const item of result.data) {
        // 匹配地址默认选项
        if (item.is_default === 1) {
          this.radio = item.id;
          return;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 2000;
  height: 58px;
  font-size: 21px;
  text-align: center;
  border-bottom: 1px solid rgb(241, 241, 241);
  .icon-nav {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
  span {
    line-height: 58px;
  }
}
.radio--group {
  margin: 58px 0;
}
.van--radio {
  padding: 0 12px;
}
/deep/.van-radio__label {
  width: 100%;
}
/deep/.van-radio__label {
  border-bottom: 1px solid rgb(231, 231, 231);
}
.select-address-one {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  background-color: #fff;
  border-radius: 13px;
  .address-info {
    padding-right: 54px;
  }
  .user-info {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
    .phone {
      font-weight: 400;
      color: #b9babe;
      margin-left: 6px;
      margin-right: 12px;
    }
    .default {
      width: 40px;
      height: 20px;
      border-radius: 5px;
      font-size: 13px;
      padding: 0 2px;
      border: 1px solid #ed301d;
      color: #ed301d;
      text-align: center;
      line-height: 20px;
    }
  }
}
.address {
  color: #6f6f6f;
  font-size: 16px;
  margin-top: 6px;
}
.button {
  border-top: 1px solid rgb(238, 238, 238);
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
}
</style>
