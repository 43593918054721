<template>
  <van-popup
    v-model="isShow"
    :close-on-click-overlay="false"
    duration="0"
    :overlay="false"
    position="bottom"
    :style="{ height: '100%' }"
  >
    <div class="nav-bar">
      <van-icon
        name="arrow-left"
        class="icon-nav"
        size="20"
        color="#9B9B9B"
        @click="closePopup"
      />
      <span>添加地址</span>
      <span class="delete" @click="addressDel">删除</span>
    </div>
    <van-form class="form">
      <van-field
        v-model="formData.consignee"
        name="收货人"
        label="收货人"
        placeholder="输入名字"
      />
      <van-field
        v-model="formData.phone"
        type="number"
        name="电话"
        label="电话"
        placeholder="输入手机号码"
      />
      <van-field
        v-model="location"
        is-link
        readonly
        label="选择地区"
        placeholder="请选择所在地区"
        @click="openCascader"
      />
      <van-field
        v-model="formData.address"
        name="详情地址"
        label="详情地址"
        placeholder="街道、楼牌号"
      />
      <van-field name="switch" label="设置默认地址">
        <template #input>
          <van-switch
            v-model="switchChecked"
            size="20"
            @change="switchChange"
          />
        </template>
      </van-field>
      <div style="margin: 16px">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          color="linear-gradient(to right, #FF3F4E, #FF3300)"
          @click="conserve"
        >
          保存地址
        </van-button>
      </div>
    </van-form>
    <CascaderPopup
      :isShow="showCascader"
      @setLocationInfo="getLocationInfo"
    ></CascaderPopup>
  </van-popup>
</template>

<script>
import CascaderPopup from "../cascader-popup/cascader-popup";
import { editAddress, getAddressInfo, delAddress } from "@/api/member";
import { Toast, Dialog } from "vant";
export default {
  name: "add-location",
  components: {
    CascaderPopup,
  },
  props: {
    isShow: {
      type: Boolean,
    },
    addressId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      switchChecked: false,
      showCascader: false,
      formData: {
        id: 0,
        consignee: "",
        phone: "",
        address: "",
        is_default: 0,
        province: "",
        city: "",
        area: "",
      },
      location: "",
    };
  },
  watch: {
    addressId(newVal, oldVal) {
      this.formData.id = newVal;
      if (newVal > 0) {
        this.getAddressInfo();
      }
    },
  },
  mounted() {
    // this.getAddressInfo();
  },
  methods: {
    conserve() {
      const isVerify = "yes";
      if (this.verification()) {
        this.editAddress(isVerify);
      }
    },
    closePopup() {
      this.emptyForm();
      this.$emit("updateLocation");
    },
    addressDel() {
      Dialog.confirm({
        title: "提示",
        message: "您确认删除地址吗？",
        className: "text-title",
      })
        .then(() => {
          this.delAddress();
        })
        .catch(() => {
          // on cancel
        });
    },
    openCascader() {
      this.showCascader = true;
    },
    switchChange(bool) {
      if (bool) {
        this.formData.is_default = 1;
      }
    },
    getLocationInfo(locationObj = {}, location) {
      this.location = location;
      if (Object.keys(locationObj).length > 0) {
        this.formData.province = locationObj.province_id;
        this.formData.city = locationObj.city_id;
        this.formData.area = locationObj.area_id;
      }
      this.showCascader = false;
    },
    async editAddress(isVerify) {
      // 保存地址
      const result = await editAddress(this.formData);
      if (result.code === 1 && isVerify === "yes") {
        Toast.success("保存成功");
        this.emptyForm();
        this.$emit("updateLocation");
      }
    },
    async getAddressInfo() {
      // 获取地址信息
      let isDefault = "";
      const result = await getAddressInfo({ id: this.formData.id });
      this.formData = result.data;
      this.location = result.data.city_names;
      isDefault = result.data.is_default;
      isDefault === 1
        ? (this.switchChecked = true)
        : (this.switchChecked = false);
    },
    async delAddress() {
      // 删除地址
      // console.log("删除前的地址ID-------", this.addressId);
      const result = await delAddress({ id: this.addressId });
      console.log(result);
      if (result.code === 1) {
        Toast.success("删除成功");
        this.emptyForm();
        this.$emit("updateLocation");
      }
    },
    verification() {
      // 验证
      for (const key in this.formData) {
        if (this.formData[key] === "") {
          Toast.fail("必填信息不能为空");
          return false;
        }
      }
      return true;
    },
    emptyForm() {
      // 清空
      this.formData = {
        id: 0,
        consignee: "",
        phone: "",
        address: "",
        is_default: 0,
        province: "",
        city: "",
        area: "",
      };
      this.location = "";
      this.formData.is_default === 1
        ? (this.switchChecked = true)
        : (this.switchChecked = false);
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 2000;
  height: 58px;
  font-size: 21px;
  text-align: center;
  border-bottom: 1px solid rgb(241, 241, 241);
  .icon-nav {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
  span {
    line-height: 58px;
  }
  .delete {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    color: #ff3406;
  }
}
.form {
  margin-top: 58px;
}
/deep/.van-field__label {
  font-size: 17px;
  color: #000;
}
</style>